<template>
    <form @submit="submitForm" novalidate="true">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування' : 'Додавання'}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.nameUk" v-model="form.nameUk"
                                          label="Назва (укр)"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.nameEn" v-model="form.nameEn"
                                          label="Назва (eng)"></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-text-field :error-messages="errors.nameShortUk" v-model="form.nameShortUk"
                                          label="Скорочено (укр)"></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-text-field :error-messages="errors.nameShortEn" v-model="form.nameShortEn"
                                          label="Скорочено (eng)"></v-text-field>
                        </v-flex>
                        <v-flex xs12 md12>
                            <v-radio-group :error-messages="errors.departmentType" v-model="form.departmentType" :mandatory="false">
                                <v-radio color="primary" label="Теоретична кафедра" :value="0"></v-radio>
                                <v-radio color="red darken-3" label="Клінічна кафедра" :value="1"></v-radio>
                            </v-radio-group>
                        </v-flex>
                        <v-flex xs12 md12>
                            <v-select
                                    :items="faculties"
                                    :error-messages="errors.faculties"
                                    v-model="form.faculty"
                                    item-text="nameUk"
                                    item-value="id"
                                    return-object
                                    label="Факультет"
                            ></v-select>
                        </v-flex>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        color="primary"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../../HttpService";
    import {remapErrors} from "../../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../../common/LoadingPlaceholder";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
        },
        components: {
            LoadingPlaceholder,
        },
        data: () => {
            return {
                form: {
                    nameUk: null,
                    nameEn: null,
                    nameShortUk: null,
                    nameShortEn: null,
                    departmentType: null,
                    faculty: null,
                    isSubjectPayable: false,
                },
                errors: {
                    nameUk: [],
                    nameEn: [],
                    nameShortUk: [],
                    nameShortEn: [],
                    departmentType: [],
                    faculty: [],
                },
                faculties: [],
                loading: false,
                loadingSave: false,
            }
        },
        mounted() {
            HttpService.getInstance()
                .get("faculties/getAll")
                .then(resp => {
                    this.faculties = resp;
                });

            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`departments/${this.editId}`)
                    .then(resp => {
                        this.loading = false;
                        this.form = resp;
                    })
            }
        },
        methods: {
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                HttpService.getInstance()
                    .post("departments", this.form)
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>