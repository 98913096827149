<template>
    <v-navigation-drawer
            fixed
            app
            :clipped="$vuetify.breakpoint.mdAndUp"
            :fixed="true"
            :value="drawer"
            width="260"
            @input="$emit('toggleDrawer', $event)"
    >
        <v-list dense>
            <v-subheader class="mt-4 subtitle-1 grey--name name--darken-1">Електронний журнал</v-subheader>
            <navigation-list-template :items="registerItems" @selectValue="setTopNavigationItem($event)"/>

            <v-subheader class="mt-4 subtitle-1 grey--name name--darken-1">Головна панель</v-subheader>
            <navigation-list-template :items="mainDashboardItems" @selectValue="setTopNavigationItem($event)"/>

            <v-subheader class="mt-4 subtitle-1 grey--name name--darken-1">Статистика</v-subheader>
            <navigation-list-template :items="statisticItems" @selectValue="setTopNavigationItem($event)"/>

            <v-subheader class="mt-4 subtitle-1 grey--name name--darken-1">Довідники</v-subheader>
            <navigation-list-template :items="dictionaryItems" @selectValue="setTopNavigationItem($event)"/>

            <v-subheader class="mt-4 subtitle-1 grey--name name--darken-1">Сервіс</v-subheader>
            <navigation-list-template :items="serviceItems" @selectValue="setTopNavigationItem($event)"/>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
    import NavigationListTemplate from '../../common/NavigationListTemplate';
    import {mapMutations} from "vuex";

    export default {
        props: {
            drawer: {
                type: Boolean,
            }
        },
        components: {
            'navigation-list-template': NavigationListTemplate,
        },
        mounted() {
            this.dictionaryItems = this.remapItems(this.dictionaryItemsRaw);
            this.registerItems = this.remapItems(this.registerItemsRaw);
            this.mainDashboardItems = this.remapItems(this.mainDashboardItemsRaw);
            this.statisticItems = this.remapItems(this.statisticItemsRaw);
            this.serviceItems = this.remapItems(this.serviceItemsRaw);
        },
        methods: {
            ...mapMutations(['setTopNavigationItem']),
            remapItems(items) {
                let itemsRemapped = items.slice(0);

                return itemsRemapped.map(item => {
                    if (item.children !== undefined) {
                        item.children = item.children.map(ch => {
                            if (ch.route && ch.route.name && this.$route.name === ch.route.name) {
                                ch.model = true;
                                this.setTopNavigationItem(ch);
                            }
                            return ch;
                        });

                        let someActiveChildren = item.children.find(ch => ch.model);

                        if (someActiveChildren !== undefined) {
                            item.model = true;
                        }

                        return item;
                    }

                    if (item.route && item.route.name && this.$route.name === item.route.name) {
                        this.setTopNavigationItem(item);
                    }

                    return item;
                })
            }
        },
        data: () => ({
            registerItems: [],
            registerItemsRaw: [
                {
                    icon: 'account_balance',
                    name: 'Журнали',
                    route: {name: 'registersRoot'}
                },
                {icon: 'assessment', name: 'Відомості', route: {name: "successSheets"}},
                {icon: 'today', name: 'Щомісячні відомості', route: {name: "inDevelopment"}},
                {icon: 'view_list', name: 'Тематичний план', route: {name: "inDevelopment"}},
                {icon: 'call_missed_outgoing', name: 'Пропуски', route: {name: "inDevelopment"}},
                {icon: 'format_align_left', name: 'Рейтинг студентів', route: {name: "inDevelopment"}},
                {icon: 'format_align_right', name: 'Рейтинг викладачів', route: {name: "inDevelopment"}},
                {icon: 'attach_money', name: 'Історія платежів', route: {name: "paymentsHistoryGrid"}},
                {icon: 'assessment', name: 'Результати КРОК', route: {name: "krokResults"}},
            ],
            mainDashboardItems: [],
            mainDashboardItemsRaw: [
                {icon: 'assignment', name: 'Оголошення', route: {name: "announcementsGrid"}},
                {icon: 'pan_tool', name: 'Опитування', route: {name: "pollsGrid"}},
                {icon: 'equalizer', name: 'Пули запитань', route: {name: "pollPoolsGrid"}},
            ],
            dictionaryItems: [],
            dictionaryItemsRaw: [
                {
                    icon: 'perm_identity',
                    name: 'Керування аккаунтами',
                    route: {name: "userManagementGrid"},
                },
                {
                    icon: 'person_add',
                    name: 'Персонал', 
                    route: {name: "staffManagementGrid"}},
                {
                    icon: 'account_box',
                    name: 'Студенти',
                    children: [
                        {name: 'Групи', route: {name: "groupsGrid"}},
                        {name: 'Студенти', route: {name: "studentsGrid"}},
                        {name: 'Логіни-паролі студентів', route: {name: "studentsAccountsGrid"}},
                        {name: 'Відраховані / Ак.відпустка', route: {name: "expelledAcademicVacation"}},
                        {name: 'Імпорт абітурієнтів/першокурсників', route: {name: "applicantsImportGrid"}},
                        {name: 'Абітурієнти', route: {name: "applicantsGrid"}},
                    ]
                },
                {
                    icon: 'perm_identity',
                    name: 'Пед. персонал',
                    children: [
                        {name: 'Викладачі', route: {name: "professorsDepartmentsGrid"}},
                        {name: 'Модератори', route: {name: "moderatorsGrid"}},
                        {name: 'Декани', route: {name: "deansGrid"}},
                        {name: 'Головні куратори', route: {name: "curatorsGrid"}},
                    ]
                },
                {
                    icon: 'grain',
                    name: 'Інше',
                    children: [
                        {name: 'Факультети', route: {name: "facultiesGrid"}},
                        {name: 'Спеціальності', route: {name: "specialitiesGrid"}},
                        {name: 'Кафедри', route: {name: "departmentsGridFaculty"}},
                        {name: 'Дисципліни', route: {name: "subjectsGrid"}},
                        {name: 'Лекції', route: {name: "lecturesRoot"}},
                        {name: 'Навчальні плани', route: {name: "educationPlan"}},
                        {name: 'Посади викладачів', route: {name: "academicPositionsGrid"}},
                        {name: 'Наукові ступені', route: {name: "scientificDegreesGrid"}},
                        {name: 'Вчені звання', route: {name: "academicStatusesGrid"}},
                        {name: 'Виробнича практика', route: {name: "practics"}},
                    ]
                },
                {
                    divider: true
                },
            ],
            statisticItems: [],
            statisticItemsRaw: [
                {
                    icon: 'trending_up',
                    name: 'Статистика оцінок',
                    children: [
                        {name: 'По факультетам', route: {name: "statisticsMarks"}},
                        {name: 'По кафедрам', route: {name: "in-development"}},
                    ]
                },
                {
                    icon: 'timeline',
                    name: 'Статистика ПМК',
                    children: [
                        {name: 'По факультетам', route: {name: "in-development"}},
                        {name: 'По кафедрам', route: {name: "in-development"}},
                    ]
                },
            ],
            serviceItems: [],
            serviceItemsRaw: [
                {icon: 'comment', name: 'Аудит', route: {name: "audit"}},
                {icon: 'info_outline', name: 'Логи', route: {name: "logs"}},
                {icon: 'build', name: 'Системні налаштування', route: {name: "systemSettings"}},
            ]
        })
    }
</script>